"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DataImportList = void 0;

var _react = _interopRequireDefault(require("react"));

var _reactHelmet = require("react-helmet");

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _icons = require("@ant-design/icons");

var _reactRouterDom = require("react-router-dom");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _rbac = require("@opensrp/rbac");

var _reactQuery = require("react-query");

var _utils = require("../../helpers/utils");

var _statusTag = require("../../components/statusTag");

var DataImportList = function DataImportList() {
  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var history = (0, _reactRouterDom.useHistory)();

  var _useQuery = (0, _reactQuery.useQuery)(_constants.dataImportRQueryKey, function () {
    var service = new _reactUtils.OpenSRPService('/$import', _constants.IMPORT_DOMAIN_URI);
    return service.list().then(function (res) {
      return res;
    });
  }),
      data = _useQuery.data,
      isFetching = _useQuery.isFetching,
      isLoading = _useQuery.isLoading,
      error = _useQuery.error;

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var columns = [{
    title: t('Workflow Id'),
    dataIndex: 'workflowId'
  }, {
    title: t('Resource upload'),
    dataIndex: 'workflowType'
  }, {
    title: t('File name'),
    dataIndex: 'filename'
  }, {
    title: t('status'),
    dataIndex: 'status',
    render: function render(_) {
      return _react["default"].createElement(_statusTag.ImportStatusTag, {
        statusString: _
      });
    }
  }, {
    title: t('Date created'),
    dataIndex: 'dateCreated',
    defaultSortOrder: 'descend',
    sortDirections: ['ascend', 'descend'],
    sorter: function sorter(a, b) {
      var diff = a.dateCreated - b.dateCreated;
      return diff === 0 ? 0 : diff > 0 ? 1 : -1;
    },
    render: function render(_) {
      return (0, _utils.formatTimestamp)(_);
    }
  }, {
    title: t('Actions'),
    width: '10%',
    render: function render(_, record) {
      return _react["default"].createElement("span", {
        className: "d-flex align-items-center"
      }, _react["default"].createElement(_rbac.RbacCheck, {
        permissions: ['DataImport.read']
      }, _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_reactRouterDom.Link, {
        to: "".concat(_constants.DATA_IMPORT_DETAIL_URL, "/").concat(record.workflowId.toString()),
        className: "m-0 p-1"
      }, t('View')))));
    }
  }];
  var tableProps = {
    datasource: data !== null && data !== void 0 ? data : [],
    columns: columns,
    loading: isFetching || isLoading
  };
  var pageTitle = t('Data imports');
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, pageTitle)), _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement("div", null), _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['DataImport.create']
  }, _react["default"].createElement(_reactRouterDom.Link, {
    to: _constants.DATA_IMPORT_CREATE_URL
  }, _react["default"].createElement(_antd.Button, {
    type: "primary",
    onClick: function onClick() {
      return history.push(_constants.DATA_IMPORT_CREATE_URL);
    }
  }, _react["default"].createElement(_icons.CloudUploadOutlined, null), t('Bulk upload'))))), _react["default"].createElement(_reactUtils.TableLayout, tableProps))));
};

exports.DataImportList = DataImportList;