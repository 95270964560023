"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseKeycloakClientRoles = exports.parseFHirRoles = exports.adapter = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _constants = require("../constants");

var _roleDefinition = require("../roleDefinition");

var _pkgConfig = require("@opensrp/pkg-config");

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var fhirVerbToPermitLookup = {
  GET: _constants.Permit.READ,
  POST: _constants.Permit.CREATE,
  PUT: _constants.Permit.UPDATE,
  DELETE: _constants.Permit.DELETE,
  MANAGE: _constants.Permit.MANAGE
};

var getFhirResourceString = function getFhirResourceString(rawResourceString) {
  var matchedResource = _constants.allSupportedRoles.filter(function (resource) {
    return resource.toUpperCase() === rawResourceString.toUpperCase();
  });

  return matchedResource[0];
};

var parseFHirRoles = function parseFHirRoles(role) {
  var separator = '_';
  var roleParts = role.split(separator);

  if (roleParts.length < 2) {
    return;
  }

  var _roleParts = (0, _toArray2["default"])(roleParts),
      verb = _roleParts[0],
      rest = _roleParts.slice(1);

  var rawResource = rest.join(separator);
  var resource = getFhirResourceString(rawResource);
  var permit = fhirVerbToPermitLookup[verb.toUpperCase()];

  if (resource && permit) {
    return new _roleDefinition.UserRole(resource, permit);
  }
};

exports.parseFHirRoles = parseFHirRoles;

var parseKeycloakClientRoles = function parseKeycloakClientRoles(scope, stringRole) {
  var _getConfig, _keycloakRoleMappings;

  var configuredClientId = (_getConfig = (0, _pkgConfig.getConfig)(_pkgConfig.clientIdConfig)) !== null && _getConfig !== void 0 ? _getConfig : '';
  var keycloakRoleMappings = {
    'realm-management': {
      'realm-admin': new _roleDefinition.UserRole(['iam_group', 'iam_role', 'iam_user', 'iam_realm'], _constants.Permit.MANAGE),
      'manage-realm': new _roleDefinition.UserRole(['iam_group', 'iam_role', 'iam_user', 'iam_realm'], _constants.Permit.MANAGE),
      'view-realm': new _roleDefinition.UserRole(['iam_group', 'iam_role', 'iam_user', 'iam_realm'], _constants.Permit.READ),
      'query-realm': new _roleDefinition.UserRole(['iam_group', 'iam_role', 'iam_user', 'iam_realm'], _constants.Permit.READ),
      'view-users': new _roleDefinition.UserRole(['iam_user'], _constants.Permit.READ),
      'query-users': new _roleDefinition.UserRole(['iam_user'], _constants.Permit.READ),
      'manage-users': new _roleDefinition.UserRole(['iam_user'], _constants.Permit.MANAGE),
      'query-groups': new _roleDefinition.UserRole(['iam_group'], _constants.Permit.READ),
      'view-groups': new _roleDefinition.UserRole(['iam_group'], _constants.Permit.READ)
    },
    account: {
      'manage-account': new _roleDefinition.UserRole(['account_user', 'account_application', 'account_group'], _constants.Permit.MANAGE),
      'view-groups': new _roleDefinition.UserRole(['account_group'], _constants.Permit.READ)
    }
  };

  if (scope === configuredClientId) {
    return parseFHirRoles(stringRole);
  }

  var lookedURole = (_keycloakRoleMappings = keycloakRoleMappings[scope]) === null || _keycloakRoleMappings === void 0 ? void 0 : _keycloakRoleMappings[stringRole];
  return lookedURole;
};

exports.parseKeycloakClientRoles = parseKeycloakClientRoles;
var defaultRoleData = {
  realmAccess: [],
  clientRoles: {}
};

var adapter = function adapter() {
  var _roles$realmAccess, _roles$clientRoles;

  var roles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultRoleData;
  var allRoleStrings = (_roles$realmAccess = roles.realmAccess) !== null && _roles$realmAccess !== void 0 ? _roles$realmAccess : [];
  var allRoles = [];
  var invalidRoleStrings = [];

  var _iterator = _createForOfIteratorHelper(allRoleStrings),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var role = _step.value;
      var asRoleDef = parseFHirRoles(role);

      if (asRoleDef) {
        allRoles.push(asRoleDef);
      } else {
        invalidRoleStrings.push(role);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  Object.entries((_roles$clientRoles = roles.clientRoles) !== null && _roles$clientRoles !== void 0 ? _roles$clientRoles : {}).forEach(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
        scope = _ref2[0],
        roleArray = _ref2[1];

    roleArray.forEach(function (role) {
      var asRole = parseKeycloakClientRoles(scope, role);

      if (asRole === undefined) {
        asRole = parseFHirRoles(role);
      }

      if (asRole) {
        allRoles.push(asRole);
      } else {
        invalidRoleStrings.push(role);
      }
    });
  });

  if (invalidRoleStrings.length > 0) {
    console.warn("Could not understand the following roles: ".concat(invalidRoleStrings.join(', ')));
  }

  return _roleDefinition.UserRole.combineRoles(allRoles);
};

exports.adapter = adapter;