"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unicefSectionValueSetURI = exports.unicefDonorValueSetURI = exports.snomedCodeSystemUri = exports.smartregisterSystemUri = exports.servicePointProfileInventoryListCoding = exports.servicePointCheckCoding = exports.serialNumberIdentifierCoding = exports.sectionCharacteristicCoding = exports.quantityCharacteristicCoding = exports.productCoding = exports.productCheckCoding = exports.poNumberIdentifierCoding = exports.loincMedicalRecordCodeableCode = exports.loincCodeSystemUri = exports.keycloakIdentifierCoding = exports.inventoryRelTagIdSystemUri = exports.inventoryRelTagIdCoding = exports.inventoryLocationTagIdSystemUri = exports.inventoryLocationTagCoding = exports.inventoryGroupCoding = exports.hl7PhysicalTypeCodeSystemUri = exports.eusmServicePointValueSetURI = exports.eusmServicePointCodeSystemUri = exports.donorCharacteristicCoding = exports.deviceSettingCodeableCode = exports.consultBeneficiaryCoding = exports.administrativeLevelSystemUri = void 0;
var smartregisterSystemUri = 'http://smartregister.org/codes';
exports.smartregisterSystemUri = smartregisterSystemUri;
var eusmServicePointCodeSystemUri = 'http://smartregister.org/CodeSystem/eusm-service-point-type';
exports.eusmServicePointCodeSystemUri = eusmServicePointCodeSystemUri;
var snomedCodeSystemUri = 'http://snomed.info/sct';
exports.snomedCodeSystemUri = snomedCodeSystemUri;
var loincCodeSystemUri = 'http://loinc.org';
exports.loincCodeSystemUri = loincCodeSystemUri;
var hl7PhysicalTypeCodeSystemUri = 'http://terminology.hl7.org/CodeSystem/location-physical-type';
exports.hl7PhysicalTypeCodeSystemUri = hl7PhysicalTypeCodeSystemUri;
var administrativeLevelSystemUri = 'https://smartregister.org/codes/administrative-level';
exports.administrativeLevelSystemUri = administrativeLevelSystemUri;
var baseValuSetURI = 'http://smartregister.org/ValueSet';
var unicefSectionValueSetURI = "".concat(baseValuSetURI, "/eusm-unicef-sections");
exports.unicefSectionValueSetURI = unicefSectionValueSetURI;
var unicefDonorValueSetURI = "".concat(baseValuSetURI, "/eusm-donors");
exports.unicefDonorValueSetURI = unicefDonorValueSetURI;
var eusmServicePointValueSetURI = "".concat(baseValuSetURI, "/eusm-service-point-type");
exports.eusmServicePointValueSetURI = eusmServicePointValueSetURI;
var inventoryRelTagIdSystemUri = 'https://smartregister.org/related-entity-location-tag-id';
exports.inventoryRelTagIdSystemUri = inventoryRelTagIdSystemUri;
var inventoryLocationTagIdSystemUri = 'https://smartregister.org/location-tag-id';
exports.inventoryLocationTagIdSystemUri = inventoryLocationTagIdSystemUri;
var deviceSettingCodeableCode = '1156600005';
exports.deviceSettingCodeableCode = deviceSettingCodeableCode;
var loincMedicalRecordCodeableCode = '11503-0';
exports.loincMedicalRecordCodeableCode = loincMedicalRecordCodeableCode;
var poNumberIdentifierCoding = {
  system: smartregisterSystemUri,
  code: 'PONUM',
  display: 'PO Number'
};
exports.poNumberIdentifierCoding = poNumberIdentifierCoding;
var sectionCharacteristicCoding = {
  system: smartregisterSystemUri,
  code: '98734231',
  display: 'Unicef Section'
};
exports.sectionCharacteristicCoding = sectionCharacteristicCoding;
var donorCharacteristicCoding = {
  system: smartregisterSystemUri,
  code: '45981276',
  display: 'Donor'
};
exports.donorCharacteristicCoding = donorCharacteristicCoding;
var inventoryGroupCoding = {
  system: smartregisterSystemUri,
  code: '78991122',
  display: 'Supply Inventory'
};
exports.inventoryGroupCoding = inventoryGroupCoding;
var servicePointProfileInventoryListCoding = {
  system: smartregisterSystemUri,
  code: '22138876',
  display: 'Supply Inventory List'
};
exports.servicePointProfileInventoryListCoding = servicePointProfileInventoryListCoding;
var supplyMgSnomedCode = '386452003';
var productCoding = {
  system: snomedCodeSystemUri,
  code: supplyMgSnomedCode,
  display: 'Supply management'
};
exports.productCoding = productCoding;
var serialNumberIdentifierCoding = {
  system: smartregisterSystemUri,
  code: 'SERNUM',
  display: 'Serial Number'
};
exports.serialNumberIdentifierCoding = serialNumberIdentifierCoding;
var quantityCharacteristicCoding = {
  system: smartregisterSystemUri,
  code: '33467722',
  display: 'Quantity'
};
exports.quantityCharacteristicCoding = quantityCharacteristicCoding;
var keycloakIdentifierCoding = {
  system: 'http://hl7.org/fhir/identifier-type',
  code: 'KUID',
  display: 'Keycloak user ID'
};
exports.keycloakIdentifierCoding = keycloakIdentifierCoding;
var servicePointCheckCoding = {
  system: 'http://smartregister.org/',
  code: 'SPCHECK',
  display: 'Service Point Check'
};
exports.servicePointCheckCoding = servicePointCheckCoding;
var consultBeneficiaryCoding = {
  system: 'http://smartregister.org/',
  code: 'CNBEN'
};
exports.consultBeneficiaryCoding = consultBeneficiaryCoding;
var productCheckCoding = {
  system: 'http://smartregister.org/',
  code: 'PRODCHECK',
  display: 'Product Check'
};
exports.productCheckCoding = productCheckCoding;
var inventoryRelTagIdCoding = {
  system: inventoryRelTagIdSystemUri,
  display: 'Related Entity Location',
  code: ''
};
exports.inventoryRelTagIdCoding = inventoryRelTagIdCoding;
var inventoryLocationTagCoding = {
  system: inventoryLocationTagIdSystemUri,
  display: 'Practitioner Location',
  code: ''
};
exports.inventoryLocationTagCoding = inventoryLocationTagCoding;