"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PatientStatus = void 0;
exports.buildObservationValueString = buildObservationValueString;
exports.getObservationLabel = getObservationLabel;
exports.getPatientName = getPatientName;
exports.getPatientStatus = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _reactUtils = require("@opensrp/react-utils");

var PatientStatus;
exports.PatientStatus = PatientStatus;

(function (PatientStatus) {
  PatientStatus["ACTIVE"] = "Active";
  PatientStatus["InACTIVE"] = "Inactive";
  PatientStatus["DECEASED"] = "Deceased";
})(PatientStatus || (exports.PatientStatus = PatientStatus = {}));

function getPatientName(patient) {
  var _patient$name;

  if (!patient) {
    return '';
  }

  var name = (_patient$name = patient.name) === null || _patient$name === void 0 ? void 0 : _patient$name[0];
  return (0, _reactUtils.parseFhirHumanName)(name);
}

function getObservationLabel(obj) {
  return (0, _get["default"])(obj, 'code.coding.0.display') || (0, _get["default"])(obj, 'code.text') || (0, _get["default"])(obj, 'valueQuantity.code');
}

function buildObservationValueString(obj) {
  var quantValue = '';

  if (obj.component && Array.isArray(obj.component)) {
    obj.component.forEach(function (c, i) {
      quantValue = quantValue + "".concat(getObservationLabel(c).replace('Blood Pressure', ''), ": ").concat((0, _get["default"])(c, 'valueQuantity.value') || '').concat((0, _get["default"])(c, 'valueQuantity.unit') || '').concat(i > 0 ? '' : ', ');
    });
  } else {
    quantValue = "".concat((0, _get["default"])(obj, 'valueQuantity.value') || '', " ").concat((0, _get["default"])(obj, 'valueQuantity.unit') || '') || 'N/A';
  }

  return quantValue;
}

var getPatientStatus = function getPatientStatus(isActive, isDeceased, t) {
  if (isDeceased) {
    return {
      title: t('Deceased'),
      color: 'red'
    };
  }

  if (isActive) {
    return {
      title: t('Active'),
      color: 'green'
    };
  }

  return {
    title: t('Inactive'),
    color: 'gray'
  };
};

exports.getPatientStatus = getPatientStatus;