"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContextProvider = void 0;
exports.fillSearchableSelect = fillSearchableSelect;
exports.superUserRole = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _store = require("@opensrp/store");

var _reactRedux = require("react-redux");

var _react = _interopRequireDefault(require("react"));

var _rbac = require("@opensrp/rbac");

var _connectedReducerRegistry = require("@onaio/connected-reducer-registry");

var _reactRouter = require("react-router");

var _react2 = require("@testing-library/react");

var _userEvent = _interopRequireDefault(require("@testing-library/user-event"));

var superUserRole = new _rbac.UserRole(_rbac.allSupportedRoles, _rbac.Permit.MANAGE);
exports.superUserRole = superUserRole;

var ContextProvider = function ContextProvider(props) {
  var children = props.children;
  return _react["default"].createElement(_reactRedux.Provider, {
    store: _store.store
  }, _react["default"].createElement(_reactRouter.Router, {
    history: _connectedReducerRegistry.history
  }, _react["default"].createElement(_rbac.RoleContext.Provider, {
    value: superUserRole
  }, children)));
};

exports.ContextProvider = ContextProvider;

function fillSearchableSelect(searchableSelectOptions) {
  var selectId = searchableSelectOptions.selectId,
      fullOptionText = searchableSelectOptions.fullOptionText,
      searchOptionText = searchableSelectOptions.searchOptionText,
      beforeFilterOptions = searchableSelectOptions.beforeFilterOptions,
      afterFilterOptions = searchableSelectOptions.afterFilterOptions;
  var selectComponent = document.querySelector("input#".concat(selectId));

  _react2.fireEvent.mouseDown(selectComponent);

  var optionTexts = (0, _toConsumableArray2["default"])(document.querySelectorAll("#".concat(selectId, "_list+div.rc-virtual-list .ant-select-item-option-content"))).map(function (option) {
    return option.textContent;
  });
  expect(optionTexts).toHaveLength(beforeFilterOptions.length);
  expect(optionTexts).toEqual(beforeFilterOptions);

  _userEvent["default"].type(selectComponent, searchOptionText);

  var afterFilterOptionTexts = (0, _toConsumableArray2["default"])(document.querySelectorAll("#".concat(selectId, "_list+div.rc-virtual-list .ant-select-item-option-content"))).map(function (option) {
    return option.textContent;
  });
  expect(afterFilterOptionTexts).toEqual(afterFilterOptions);

  _react2.fireEvent.click(document.querySelector("[title=\"".concat(fullOptionText, "\"]")));
}