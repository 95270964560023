"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clientIdConfig = exports.USER_PREFERENCE_KEY = exports.SLICE_NOT_REGISTERED = void 0;
var SLICE_NOT_REGISTERED = 'Looks like configuration slice is being used without having been yet registered to the store';
exports.SLICE_NOT_REGISTERED = SLICE_NOT_REGISTERED;
var USER_PREFERENCE_KEY = 'Preference';
exports.USER_PREFERENCE_KEY = USER_PREFERENCE_KEY;
var clientIdConfig = 'clientId';
exports.clientIdConfig = clientIdConfig;