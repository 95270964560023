"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnitOfMeasure = void 0;
exports.defaultValidationRulesFactory = defaultValidationRulesFactory;
exports.validateFileFactory = exports.normalizeFileInputEvent = exports.groupSelectfilterFunction = exports.getUnitOfMeasureOptions = exports.getGroupTypeOptions = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _constants = require("../../constants");

var _lodash = require("lodash");

var _fhirHelpers = require("@opensrp/fhir-helpers");

var UnitOfMeasure;
exports.UnitOfMeasure = UnitOfMeasure;

(function (UnitOfMeasure) {
  UnitOfMeasure["Pieces"] = "Pieces";
  UnitOfMeasure["Tablets"] = "Tablets";
  UnitOfMeasure["Ampoules"] = "Ampoules";
  UnitOfMeasure["Strips"] = "Strips";
  UnitOfMeasure["Cycles"] = "Cycles";
  UnitOfMeasure["Bottles"] = "Bottles";
  UnitOfMeasure["TestKits"] = "Test kits";
  UnitOfMeasure["Sachets"] = "Sachets";
  UnitOfMeasure["Straps"] = "Straps";
})(UnitOfMeasure || (exports.UnitOfMeasure = UnitOfMeasure = {}));

var normalizeFileInputEvent = function normalizeFileInputEvent(e) {
  if (Array.isArray(e)) {
    return e;
  }

  return e.fileList;
};

exports.normalizeFileInputEvent = normalizeFileInputEvent;

var validateFileFactory = function validateFileFactory(t) {
  return function (_, fileList) {
    if (fileList && fileList.length > 0) {
      var file = fileList[0].originFileObj;
      var MAX_IMAGE_SIZE_MB = 5;

      if (file && file.size / 1024 / 1024 > MAX_IMAGE_SIZE_MB) {
        return Promise.reject(new Error(t('File must be smaller than 5MB!')));
      }
    }

    return Promise.resolve();
  };
};

exports.validateFileFactory = validateFileFactory;

function defaultValidationRulesFactory(t) {
  var _ref;

  return _ref = {}, (0, _defineProperty2["default"])(_ref, _constants.id, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.identifier, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.name, [{
    type: 'string',
    message: t('Must be a valid string')
  }]), (0, _defineProperty2["default"])(_ref, _constants.active, [{
    type: 'boolean'
  }]), (0, _defineProperty2["default"])(_ref, _constants.type, [{
    type: 'enum',
    "enum": Object.values(_fhirHelpers.R4GroupTypeCodes)
  }]), (0, _defineProperty2["default"])(_ref, _constants.unitOfMeasure, [{
    type: 'enum',
    "enum": Object.values(UnitOfMeasure)
  }]), (0, _defineProperty2["default"])(_ref, _constants.materialNumber, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.isAttractiveItem, [{
    type: 'boolean'
  }]), (0, _defineProperty2["default"])(_ref, _constants.availability, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.condition, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.appropriateUsage, [{
    type: 'string'
  }]), (0, _defineProperty2["default"])(_ref, _constants.accountabilityPeriod, [{
    type: 'number'
  }]), (0, _defineProperty2["default"])(_ref, _constants.productImage, [{
    type: 'array',
    max: 1
  }, {
    validator: validateFileFactory(t)
  }]), _ref;
}

var getGroupTypeOptions = function getGroupTypeOptions() {
  return (0, _lodash.values)(_fhirHelpers.R4GroupTypeCodes).map(function (group) {
    return {
      value: group,
      label: (0, _lodash.capitalize)(group)
    };
  });
};

exports.getGroupTypeOptions = getGroupTypeOptions;

var getUnitOfMeasureOptions = function getUnitOfMeasureOptions() {
  return (0, _lodash.values)(UnitOfMeasure).map(function (measure) {
    return {
      value: measure,
      label: (0, _lodash.capitalize)(measure)
    };
  });
};

exports.getUnitOfMeasureOptions = getUnitOfMeasureOptions;

var groupSelectfilterFunction = function groupSelectfilterFunction(inputValue, option) {
  return !!(option !== null && option !== void 0 && option.label.toLowerCase().includes(inputValue.toLowerCase()));
};

exports.groupSelectfilterFunction = groupSelectfilterFunction;