"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultUserFormInitialValues = exports.commonFhirFields = exports.UserForm = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactRouter = require("react-router");

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _utils = require("./utils");

var _notifications = require("@opensrp/notifications");

require("../../../index.css");

var _mls = require("../../../mls");

var _constants = require("../../../constants");

var _Credentials = require("../../Credentials");

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var UserForm = function UserForm(props) {
  var _initialValues$enable;

  var initialValues = props.initialValues,
      keycloakBaseURL = props.keycloakBaseURL,
      baseUrl = props.baseUrl,
      practitionerUpdaterFactory = props.practitionerUpdaterFactory,
      extraData = props.extraData,
      userGroups = props.userGroups,
      renderFields = props.renderFields,
      hiddenFields = props.hiddenFields;

  var shouldRender = function shouldRender(fieldName) {
    if (renderFields === undefined) {
      return true;
    }

    return !!renderFields.includes(fieldName);
  };

  var isHidden = function isHidden(fieldName) {
    return !!(hiddenFields !== null && hiddenFields !== void 0 && hiddenFields.includes(fieldName));
  };

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var practitionerUpdater = practitionerUpdaterFactory(baseUrl);

  var _Form$useForm = _antd.Form.useForm(),
      _Form$useForm2 = (0, _slicedToArray2["default"])(_Form$useForm, 1),
      form = _Form$useForm2[0];

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      isSubmitting = _useState2[0],
      setSubmitting = _useState2[1];

  var history = (0, _reactRouter.useHistory)();
  var status = [{
    label: t('Yes'),
    value: true
  }, {
    label: t('No'),
    value: false
  }];
  (0, _react.useEffect)(function () {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  var _useState3 = (0, _react.useState)((_initialValues$enable = initialValues.enabled) !== null && _initialValues$enable !== void 0 ? _initialValues$enable : false),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      userEnabled = _useState4[0],
      setUserEnabled = _useState4[1];

  (0, _react.useEffect)(function () {
    if (!userEnabled) {
      form.setFields([{
        name: 'active',
        value: false
      }]);
    } else {
      form.setFields([{
        name: 'active',
        value: initialValues.active
      }]);
    }
  }, [form, initialValues, userEnabled]);
  var editing = !!props.initialValues.id;
  var pageTitle = editing ? t('Edit User | {{username}}', {
    username: initialValues.username
  }) : t('Add User');
  var headerProps = {
    pageHeaderProps: {
      title: pageTitle,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_antd.Row, null, _react["default"].createElement(_antd.Col, {
    className: "bg-white p-3",
    span: 24
  }, _react["default"].createElement(_antd.Form, (0, _extends2["default"])({}, _reactUtils.formItemLayout, {
    form: form,
    initialValues: initialValues,
    onFinish: function onFinish(values) {
      setSubmitting(true);
      (0, _utils.submitForm)(_objectSpread(_objectSpread({}, initialValues), values), keycloakBaseURL, userGroups, initialValues.userGroups, practitionerUpdater, t)["catch"](function (_) {
        if (props.initialValues.id) {
          (0, _notifications.sendErrorNotification)(t('There was a problem updating user details'));
        }

        (0, _notifications.sendErrorNotification)(t('There was a problem creating User'));
      })["finally"](function () {
        return setSubmitting(false);
      });
    }
  }), shouldRender(_constants.firstNameField) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.firstNameField,
    id: _constants.firstNameField,
    hidden: isHidden(_constants.firstNameField),
    label: t('First Name'),
    rules: [{
      required: true,
      message: t('First Name is required')
    }]
  }, _react["default"].createElement(_antd.Input, null)) : null, shouldRender(_constants.lastNameField) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.lastNameField,
    id: _constants.lastNameField,
    hidden: isHidden(_constants.lastNameField),
    label: t('Last Name'),
    rules: [{
      required: true,
      message: t('Last Name is required')
    }]
  }, _react["default"].createElement(_antd.Input, null)) : null, shouldRender(_constants.NATIONAL_ID_FORM_FIELD) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.NATIONAL_ID_FORM_FIELD,
    id: _constants.NATIONAL_ID_FORM_FIELD,
    hidden: isHidden(_constants.NATIONAL_ID_FORM_FIELD),
    label: t('National Id'),
    rules: [{
      pattern: /^\d{16}$/,
      message: t('National Id number with 16 digits'),
      required: true
    }]
  }, _react["default"].createElement(_antd.Input, null)) : null, shouldRender(_constants.PHONE_NUMBER_FORM_FIELD) ? _react["default"].createElement(_antd.Form.Item, {
    hidden: isHidden(_constants.PHONE_NUMBER_FORM_FIELD),
    name: _constants.PHONE_NUMBER_FORM_FIELD,
    id: _constants.PHONE_NUMBER_FORM_FIELD,
    label: t('Mobile Phone Number'),
    rules: [{
      pattern: /^\d{10,16}$/,
      required: true,
      message: t('Please enter a Phone number with 10 to 16 digits.')
    }]
  }, _react["default"].createElement(_antd.Input, null)) : null, shouldRender(_constants.emailField) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.emailField,
    id: _constants.emailField,
    label: t('Email')
  }, _react["default"].createElement(_antd.Input, null)) : null, shouldRender(_constants.usernameField) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.usernameField,
    id: _constants.usernameField,
    label: t('Username'),
    rules: [{
      required: true,
      message: t('Username is required')
    }]
  }, _react["default"].createElement(_antd.Input, {
    disabled: initialValues.id ? true : false
  })) : null, shouldRender(_constants.passwordField) && !editing ? _react["default"].createElement(_Credentials.CredentialsFieldsRender, null) : null, shouldRender(_constants.userTypeField) ? _react["default"].createElement(_antd.Form.Item, {
    id: _constants.userTypeField,
    name: _constants.userTypeField,
    label: t('User Type'),
    hidden: isHidden(_constants.userTypeField)
  }, _react["default"].createElement(_antd.Radio.Group, {
    options: [{
      label: t('Practitioner'),
      value: _constants.PRACTITIONER
    }, {
      label: t('Supervisor'),
      value: _constants.SUPERVISOR
    }]
  })) : null, _react["default"].createElement(_antd.Form.Item, {
    id: _constants.enabledField,
    name: _constants.enabledField,
    label: t('Enable user'),
    hidden: isHidden(_constants.enabledField)
  }, _react["default"].createElement(_antd.Radio.Group, {
    options: status,
    onChange: function onChange(e) {
      return setUserEnabled(e.target.value);
    }
  })), initialValues.id && initialValues.id !== extraData.user_id && shouldRender('active') ? _react["default"].createElement(_antd.Form.Item, {
    id: "practitionerToggle",
    name: "active",
    label: t('Mark as Practitioner'),
    hidden: isHidden('active')
  }, _react["default"].createElement(_antd.Radio.Group, {
    name: "active"
  }, status.map(function (e) {
    return _react["default"].createElement(_antd.Radio, {
      name: "active",
      key: e.label,
      value: e.value,
      disabled: !userEnabled
    }, e.label);
  }))) : null, shouldRender(_constants.userGroupsField) ? _react["default"].createElement(_antd.Form.Item, {
    name: _constants.userGroupsField,
    id: _constants.userGroupsField,
    hidden: isHidden(_constants.userGroupsField),
    label: t('Keycloak User Group')
  }, _react["default"].createElement(_antd.Select, {
    mode: "multiple",
    allowClear: true,
    placeholder: t('Please select'),
    style: {
      width: '100%'
    },
    options: (0, _utils.getUserGroupsOptions)(userGroups),
    filterOption: _utils.userGroupOptionsFilter
  })) : null, shouldRender(_constants.fhirCoreAppIdField) ? _react["default"].createElement(_antd.Form.Item, {
    hidden: isHidden(_constants.fhirCoreAppIdField),
    id: _constants.fhirCoreAppIdField,
    name: _constants.fhirCoreAppIdField,
    label: t('Application ID'),
    rules: [{
      required: true,
      message: t('Application Id is required')
    }],
    "data-testid": "fhirCoreAppId"
  }, _react["default"].createElement(_reactUtils.ClientSideActionsSelect, {
    fhirBaseUrl: baseUrl,
    resourceType: _constants.compositionResourceType,
    transformOption: _utils.getCompositionOptions,
    extraQueryParams: _utils.compositionUrlFilter,
    showSearch: true
  })) : null, _react["default"].createElement(_antd.Form.Item, _reactUtils.tailLayout, _react["default"].createElement(_antd.Button, {
    type: "primary",
    htmlType: "submit",
    className: "create-user"
  }, isSubmitting ? t('SAVING') : t('Save')), _react["default"].createElement(_antd.Button, {
    onClick: function onClick() {
      return history.goBack();
    },
    className: "cancel-user"
  }, t('Cancel')))))));
};

exports.UserForm = UserForm;
var defaultUserFormInitialValues = {
  firstName: '',
  id: '',
  lastName: '',
  nationalId: '',
  phoneNumber: '',
  username: '',
  active: true,
  userType: 'practitioner',
  userGroups: undefined,
  practitioner: undefined,
  enabled: true,
  fhirCoreAppId: undefined
};
exports.defaultUserFormInitialValues = defaultUserFormInitialValues;
var commonFhirFields = [_constants.firstNameField, _constants.lastNameField, _constants.emailField, _constants.usernameField, _constants.userTypeField, _constants.enabledField, 'active', _constants.userGroupsField, _constants.fhirCoreAppIdField, _constants.passwordField];
exports.commonFhirFields = commonFhirFields;
UserForm.defaultProps = {
  initialValues: defaultUserFormInitialValues,
  practitionerUpdaterFactory: _utils.postPutPractitioner,
  renderFields: commonFhirFields
};