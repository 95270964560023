"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.webClientRoles = exports.realmClientResources = exports.fhirResources = exports.allSupportedRoles = exports.accountClientResources = exports.Permit = void 0;
var Permit;
exports.Permit = Permit;

(function (Permit) {
  Permit[Permit["CREATE"] = 1] = "CREATE";
  Permit[Permit["READ"] = 2] = "READ";
  Permit[Permit["UPDATE"] = 4] = "UPDATE";
  Permit[Permit["DELETE"] = 8] = "DELETE";
  Permit[Permit["MANAGE"] = 15] = "MANAGE";
})(Permit || (exports.Permit = Permit = {}));

var accountClientResources = ['account_user', 'account_application', 'account_group'];
exports.accountClientResources = accountClientResources;
var realmClientResources = ['iam_user', 'iam_realm', 'iam_group', 'iam_role'];
exports.realmClientResources = realmClientResources;
var fhirResources = ['Patient', 'Practitioner', 'PractitionerRole', 'Group', 'Organization', 'OrganizationAffiliation', 'HealthcareService', 'Location', 'Observation', 'QuestionnaireResponse', 'CareTeam', 'PlanDefinition', 'Questionnaire', 'PractitionerDetail', 'List', 'Binary', 'Condition', 'Task', 'Immunization', 'Encounter', 'Flag'];
exports.fhirResources = fhirResources;
var webClientRoles = ['DataImport'];
exports.webClientRoles = webClientRoles;
var allSupportedRoles = [].concat(fhirResources, accountClientResources, realmClientResources, webClientRoles);
exports.allSupportedRoles = allSupportedRoles;