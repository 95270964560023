"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DataImportForm = void 0;
exports.customFetchOptions = customFetchOptions;
exports.normalizeFileInputEvent = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _Upload = _interopRequireDefault(require("@2fd/ant-design-icons/lib/Upload"));

var _UploadOutline = _interopRequireDefault(require("@2fd/ant-design-icons/lib/UploadOutline"));

var _reactRouter = require("react-router");

var _reactQuery = require("react-query");

var _reactUtils = require("@opensrp/react-utils");

var _constants = require("../../constants");

var _mls = require("../../mls");

var _notifications = require("@opensrp/notifications");

var _serverService = require("@opensrp/server-service");

require("./form.css");

var _pkgConfig = require("@opensrp/pkg-config");

var _rbac = require("@opensrp/rbac");

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var Text = _antd.Typography.Text,
    Title = _antd.Typography.Title;

function customFetchOptions(_, accessToken, method, data) {
  var headers = (0, _serverService.getDefaultHeaders)(accessToken);
  return _objectSpread({
    headers: {
      authorization: headers.authorization
    },
    method: method
  }, data ? {
    body: data
  } : {});
}

var DataImportForm = function DataImportForm(props) {
  var hidden = props.hidden;
  var queryClient = (0, _reactQuery.useQueryClient)();
  var history = (0, _reactRouter.useHistory)();

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var goTo = function goTo() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#';
    return history.push(url);
  };

  var _getAllConfigs = (0, _pkgConfig.getAllConfigs)(),
      productListId = _getAllConfigs.productListId;

  var listIdsSParams = new URLSearchParams();

  if (productListId) {
    listIdsSParams.append('productListId', productListId);
  }

  var _useMutation = (0, _reactQuery.useMutation)(function () {
    var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(values) {
      var postUrl, service, formData;
      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              postUrl = "".concat(_constants.IMPORT_ENDPOINT, "?").concat(listIdsSParams.toString());
              service = new _reactUtils.OpenSRPService(postUrl, _constants.IMPORT_DOMAIN_URI, customFetchOptions);
              formData = new FormData();
              Object.entries(values).forEach(function (_ref2) {
                var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
                    key = _ref3[0],
                    value = _ref3[1];

                if (value) {
                  var _value$;

                  formData.append(key, value === null || value === void 0 ? void 0 : (_value$ = value[0]) === null || _value$ === void 0 ? void 0 : _value$.originFileObj);
                }
              });
              return _context.abrupt("return", service.create(formData));

            case 5:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function (_x) {
      return _ref.apply(this, arguments);
    };
  }(), {
    onError: function onError(err) {
      (0, _notifications.sendErrorNotification)(err.message);
    },
    onSuccess: function () {
      var _onSuccess = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2() {
        return _regenerator["default"].wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                (0, _notifications.sendSuccessNotification)(t('Data import started successfully'));
                queryClient.invalidateQueries(_constants.dataImportRQueryKey)["catch"](function () {
                  (0, _notifications.sendInfoNotification)(t('Failed to refresh data, please refresh the page'));
                });
                goTo(_constants.DATA_IMPORT_LIST_URL);

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        }, _callee2);
      }));

      function onSuccess() {
        return _onSuccess.apply(this, arguments);
      }

      return onSuccess;
    }()
  }),
      mutate = _useMutation.mutate,
      isLoading = _useMutation.isLoading;

  var formItems = [{
    formFieldName: _constants.users,
    label: t('Users'),
    UploadBtnText: t('Attach users file'),
    permissions: ['iam_user.create', 'Practitioner.create', 'iam_user.update'],
    disabled: false,
    disabledReason: undefined
  }, {
    formFieldName: _constants.locations,
    label: t('Locations'),
    UploadBtnText: t('Attach locations file'),
    permissions: ['Location.create', 'Location.update'],
    disabled: false
  }, {
    formFieldName: _constants.organizations,
    label: t('Organizations'),
    UploadBtnText: t('Attach organizations file'),
    permissions: ['Organization.create', 'Organization.update'],
    disabled: false
  }, {
    formFieldName: _constants.careteams,
    label: t('CareTeams'),
    UploadBtnText: t('Attach careTeams file'),
    permissions: ['CareTeam.create', 'CareTeam.update'],
    disabled: false
  }, {
    formFieldName: _constants.orgToLocationAssignment,
    label: t('Organization location assignment'),
    UploadBtnText: t('Attach assignment file'),
    permissions: ['OrganizationAffiliation.create', 'OrganizationAffiliation.update'],
    disabled: false
  }, {
    formFieldName: _constants.userToOrganizationAssignment,
    label: t('User organization assignment'),
    UploadBtnText: t('Attach assignment file'),
    permissions: ['PractitionerRole.create', 'PractitionerRole.update'],
    disabled: false
  }];
  return _react["default"].createElement(_antd.Space, {
    direction: "vertical",
    style: {
      width: '100%'
    }
  }, _react["default"].createElement(Title, {
    level: 4
  }, _react["default"].createElement(_Upload["default"], null), "Select files to upload"), _react["default"].createElement(Text, {
    type: "secondary"
  }, "Supported file formats CSV"), _react["default"].createElement(_antd.Form, {
    className: "import-form",
    colon: false,
    onFinish: function onFinish(values) {
      mutate(values);
    }
  }, formItems.map(function (item) {
    var formFieldName = item.formFieldName,
        label = item.label,
        UploadBtnText = item.UploadBtnText,
        permissions = item.permissions,
        disabled = item.disabled,
        disabledReason = item.disabledReason;
    var disableInfoProps = disabled && disabledReason ? {
      validateStatus: 'warning',
      help: disabledReason
    } : {};
    return _react["default"].createElement(_rbac.RbacCheck, {
      permissions: permissions,
      key: formFieldName
    }, _react["default"].createElement(_antd.Form.Item, (0, _extends2["default"])({
      key: formFieldName
    }, disableInfoProps, {
      id: formFieldName,
      hidden: hidden === null || hidden === void 0 ? void 0 : hidden.includes(formFieldName),
      name: formFieldName,
      label: label,
      valuePropName: "fileList",
      getValueFromEvent: normalizeFileInputEvent
    }), _react["default"].createElement(_antd.Upload, {
      disabled: disabled,
      id: formFieldName,
      beforeUpload: function beforeUpload() {
        return false;
      },
      accept: "text/csv",
      multiple: false,
      maxCount: 1
    }, _react["default"].createElement(_antd.Button, {
      icon: _react["default"].createElement(_UploadOutline["default"], null)
    }, UploadBtnText))));
  }), _react["default"].createElement(_antd.Form.Item, null, _react["default"].createElement(_antd.Space, null, _react["default"].createElement(_antd.Button, {
    type: "primary",
    id: "submit-button",
    disabled: isLoading,
    htmlType: "submit"
  }, isLoading ? t('Uploading') : t('Start Import'))))));
};

exports.DataImportForm = DataImportForm;

var normalizeFileInputEvent = function normalizeFileInputEvent(e) {
  if (Array.isArray(e)) {
    return e;
  }

  return e.fileList;
};

exports.normalizeFileInputEvent = normalizeFileInputEvent;