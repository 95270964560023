"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.valueSetResourceType = exports.organizationAffiliationResourceType = exports.locationResourceType = exports.listResourceType = exports.groupResourceType = exports.compositionResourceType = exports.PractitionerResourceType = exports.ObservationResourceType = exports.ListResourceType = exports.FlagResourceType = exports.EncounterResourceType = void 0;
var groupResourceType = 'Group';
exports.groupResourceType = groupResourceType;
var locationResourceType = 'Location';
exports.locationResourceType = locationResourceType;
var listResourceType = 'List';
exports.listResourceType = listResourceType;
var valueSetResourceType = 'ValueSet';
exports.valueSetResourceType = valueSetResourceType;
var organizationAffiliationResourceType = 'OrganizationAffiliation';
exports.organizationAffiliationResourceType = organizationAffiliationResourceType;
var FlagResourceType = 'Flag';
exports.FlagResourceType = FlagResourceType;
var ListResourceType = 'List';
exports.ListResourceType = ListResourceType;
var EncounterResourceType = 'Encounter';
exports.EncounterResourceType = EncounterResourceType;
var ObservationResourceType = 'Observation';
exports.ObservationResourceType = ObservationResourceType;
var PractitionerResourceType = 'Practitioner';
exports.PractitionerResourceType = PractitionerResourceType;
var compositionResourceType = 'Composition';
exports.compositionResourceType = compositionResourceType;