"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCareTeamFormFields = exports.defaultInitialValues = void 0;
exports.getPatientName = getPatientName;
exports.preloadExistingOptionsFactory = preloadExistingOptionsFactory;
exports.submitForm = exports.processPractitionerOption = exports.processOrganizationOption = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _uuid = require("uuid");

var _reactUtils = require("@opensrp/react-utils");

var _notifications = require("@opensrp/notifications");

var _constants = require("../../constants");

var _lodash = require("lodash");

var _defaultInitialValues;

var _excluded = ["meta", "text", "participant"];

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      (0, _defineProperty2["default"])(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }

  return target;
}

var getCarriedOverParticipants = function getCarriedOverParticipants(values, initialValues) {
  var _initialValues$initia;

  var _ref = (_initialValues$initia = initialValues.initialCareTeam) !== null && _initialValues$initia !== void 0 ? _initialValues$initia : {},
      participant = _ref.participant;

  var participantByKey = (participant !== null && participant !== void 0 ? participant : []).reduce(function (ac, val) {
    var _val$member;

    var reference = (_val$member = val.member) === null || _val$member === void 0 ? void 0 : _val$member.reference;
    return _objectSpread(_objectSpread({}, ac), {}, (0, _defineProperty2["default"])({}, reference, val));
  }, {});

  var currentManagingOrgValuesyId = values[_constants.managingOrganizations].reduce(function (ac, val) {
    return _objectSpread(_objectSpread({}, ac), {}, (0, _defineProperty2["default"])({}, val, val));
  }, {});

  var currentpractitionersById = values[_constants.practitionerParticipants].reduce(function (ac, val) {
    return _objectSpread(_objectSpread({}, ac), {}, (0, _defineProperty2["default"])({}, val, val));
  }, {});

  var cleanParticipants = function cleanParticipants(refs, lookup) {
    refs.forEach(function (ref) {
      if (!lookup[ref]) {
        delete participantByKey[ref];
      }
    });
  };

  cleanParticipants(initialValues.practitionerParticipants, currentpractitionersById);
  cleanParticipants(initialValues.managingOrganizations, currentManagingOrgValuesyId);
  return participantByKey;
};

var submitForm = function () {
  var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(values, initialValues, fhirBaseURL, selectedOrganizations, selectedPractitioners, t) {
    var initialCareTeam, id, uuid, _ref3, meta, text, participant, nonMetaFields, participatingOrgsPayload, practitionerParticipants, carriedOverParticipantsById, carriedOverParticipants, managingOrgsReferences, allParticipants, careTeamId, payload, serve, successNotificationMessage;

    return _regenerator["default"].wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            initialCareTeam = initialValues.initialCareTeam, id = initialValues.id, uuid = initialValues.uuid;
            _ref3 = initialCareTeam !== null && initialCareTeam !== void 0 ? initialCareTeam : {}, meta = _ref3.meta, text = _ref3.text, participant = _ref3.participant, nonMetaFields = (0, _objectWithoutProperties2["default"])(_ref3, _excluded);
            participatingOrgsPayload = selectedOrganizations.map(function (orgOption) {
              var label = orgOption.label,
                  value = orgOption.value;
              return {
                role: [{
                  coding: [{
                    system: 'http://snomed.info/sct',
                    code: '394730007',
                    display: 'Healthcare related organization'
                  }]
                }],
                member: {
                  reference: value,
                  display: label
                }
              };
            });
            practitionerParticipants = selectedPractitioners.map(function (option) {
              var label = option.label,
                  value = option.value;
              return {
                member: {
                  reference: value,
                  display: label
                }
              };
            });
            carriedOverParticipantsById = getCarriedOverParticipants(values, initialValues);
            carriedOverParticipants = Object.values(carriedOverParticipantsById);
            managingOrgsReferences = participatingOrgsPayload.map(function (payload) {
              return payload.member;
            });
            allParticipants = [].concat((0, _toConsumableArray2["default"])(carriedOverParticipants), (0, _toConsumableArray2["default"])(practitionerParticipants), (0, _toConsumableArray2["default"])(participatingOrgsPayload));
            careTeamId = uuid ? uuid : (0, _uuid.v4)();
            payload = _objectSpread(_objectSpread({}, nonMetaFields), {}, {
              resourceType: _constants.FHIR_CARE_TEAM,
              identifier: [{
                use: 'official',
                value: careTeamId
              }],
              id: id ? id : careTeamId,
              name: values.name,
              status: values.status,
              participant: allParticipants,
              managingOrganization: managingOrgsReferences
            });
            serve = new _reactUtils.FHIRServiceClass(fhirBaseURL, _constants.FHIR_CARE_TEAM);
            successNotificationMessage = t('Successfully added CareTeams');

            if (id) {
              successNotificationMessage = t('Successfully updated CareTeams');
            }

            _context.next = 15;
            return serve.update(payload).then(function () {
              return (0, _notifications.sendSuccessNotification)(successNotificationMessage);
            });

          case 15:
            return _context.abrupt("return", _context.sent);

          case 16:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));

  return function submitForm(_x, _x2, _x3, _x4, _x5, _x6) {
    return _ref2.apply(this, arguments);
  };
}();

exports.submitForm = submitForm;

function getPatientName(obj) {
  if (!obj) {
    return '';
  }

  var names = obj.name;
  var officialName = (0, _reactUtils.getObjLike)(names, 'use', _reactUtils.IdentifierUseCodes.OFFICIAL)[0];
  return (0, _reactUtils.parseFhirHumanName)(officialName);
}

var defaultInitialValues = (_defaultInitialValues = {}, (0, _defineProperty2["default"])(_defaultInitialValues, _constants.uuid, ''), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.id, ''), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.name, ''), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.status, 'active'), (0, _defineProperty2["default"])(_defaultInitialValues, "initialCareTeam", undefined), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.managingOrganizations, []), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.organizationParticipants, []), (0, _defineProperty2["default"])(_defaultInitialValues, _constants.practitionerParticipants, []), _defaultInitialValues);
exports.defaultInitialValues = defaultInitialValues;

var getCareTeamFormFields = function getCareTeamFormFields(careTeam) {
  var _careTeam$managingOrg;

  if (!careTeam) {
    return defaultInitialValues;
  }

  var id = careTeam.id,
      identifier = careTeam.identifier,
      name = careTeam.name,
      status = careTeam.status,
      participant = careTeam.participant;
  var officialIdentifier = (0, _reactUtils.getObjLike)(identifier, 'use', _reactUtils.IdentifierUseCodes.OFFICIAL);
  var participantRefs = (participant !== null && participant !== void 0 ? participant : []).map(function (participant) {
    var _participant$member;

    return (_participant$member = participant.member) === null || _participant$member === void 0 ? void 0 : _participant$member.reference;
  }).filter(function (x) {
    return x !== undefined;
  });
  var practitionerRefs = participantRefs.filter(function (ref) {
    return ref.startsWith(_constants.practitionerResourceType);
  });
  var organizationRefs = participantRefs.filter(function (ref) {
    return ref.startsWith(_constants.organizationResourceType);
  });
  var managingOrgsRefs = ((_careTeam$managingOrg = careTeam.managingOrganization) !== null && _careTeam$managingOrg !== void 0 ? _careTeam$managingOrg : []).map(function (ref) {
    return ref.reference;
  }).filter(function (ref) {
    return !!ref;
  });
  return {
    uuid: (0, _lodash.get)(officialIdentifier, '0.value', undefined),
    id: id,
    name: name,
    status: status,
    initialCareTeam: careTeam,
    practitionerParticipants: practitionerRefs,
    organizationParticipants: organizationRefs,
    managingOrganizations: managingOrgsRefs
  };
};

exports.getCareTeamFormFields = getCareTeamFormFields;

function preloadExistingOptionsFactory(fhirBaseUrl, optionsPreprocessor) {
  return function () {
    var _preloadExistingOptions = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(values) {
      var service, batchPayload;
      return _regenerator["default"].wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              service = new _reactUtils.FHIRServiceClass(fhirBaseUrl, '');
              batchPayload = {
                resourceType: 'Bundle',
                type: 'batch',
                entry: values.map(function (value) {
                  return {
                    request: {
                      method: 'GET',
                      url: value
                    }
                  };
                })
              };
              return _context2.abrupt("return", service.customRequest({
                method: 'POST',
                body: JSON.stringify(batchPayload),
                url: fhirBaseUrl
              }).then(function (response) {
                return (0, _reactUtils.getResourcesFromBundle)(response).map(optionsPreprocessor);
              })["catch"](function () {
                return [];
              }));

            case 3:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2);
    }));

    function preloadExistingOptions(_x7) {
      return _preloadExistingOptions.apply(this, arguments);
    }

    return preloadExistingOptions;
  }();
}

var processPractitionerOption = function processPractitionerOption(obj) {
  return {
    value: "".concat(obj.resourceType, "/").concat(obj.id),
    label: getPatientName(obj),
    ref: obj
  };
};

exports.processPractitionerOption = processPractitionerOption;

var processOrganizationOption = function processOrganizationOption(obj) {
  return {
    value: "".concat(obj.resourceType, "/").concat(obj.id),
    label: obj.name,
    ref: obj
  };
};

exports.processOrganizationOption = processOrganizationOption;