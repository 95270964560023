"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserList = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _react = _interopRequireDefault(require("react"));

var _antd = require("antd");

var _reactUtils = require("@opensrp/react-utils");

var _icons = require("@ant-design/icons");

var _userManagement = require("@opensrp/user-management");

var _utils = require("./utils");

var _tableColumns = require("./tableColumns");

var _reactRouter = require("react-router");

var _reactHelmet = require("react-helmet");

var _reactQuery = require("react-query");

var _sessionReducer = require("@onaio/session-reducer");

var _reactRedux = require("react-redux");

var _i18n = require("@opensrp/i18n");

var _rbac = require("@opensrp/rbac");

var _ViewdetailsOverview = require("../ViewdetailsOverview");

var _mls = require("../../../mls");

var nameFilterKey = 'name';
var activeFilterKey = 'status';

var UserList = function UserList(props) {
  var _sParams$get, _data$records, _filterRegistry$activ;

  var fhirBaseURL = props.fhirBaseURL,
      keycloakBaseURL = props.keycloakBaseURL;
  var location = (0, _reactRouter.useLocation)();
  var history = (0, _reactRouter.useHistory)();
  var match = (0, _reactRouter.useRouteMatch)();
  var extraData = (0, _reactRedux.useSelector)(_sessionReducer.getExtraData);

  var _useTranslation = (0, _mls.useTranslation)(),
      t = _useTranslation.t;

  var userRole = (0, _rbac.useUserRole)();

  var _useSearchParams = (0, _reactUtils.useSearchParams)(),
      sParams = _useSearchParams.sParams;

  var resourceId = (_sParams$get = sParams.get(_reactUtils.viewDetailsQuery)) !== null && _sParams$get !== void 0 ? _sParams$get : undefined;

  var _useQuery = (0, _reactQuery.useQuery)([_userManagement.KEYCLOAK_URL_USERS], function () {
    return (0, _utils.loadKeycloakResources)(keycloakBaseURL, _userManagement.KEYCLOAK_URL_USERS);
  }),
      isLoading = _useQuery.isLoading,
      data = _useQuery.data,
      error = _useQuery.error,
      isFetching = _useQuery.isFetching;

  var _useClientSideDataGri = (0, _reactUtils.useClientSideDataGridFilters)((_data$records = data === null || data === void 0 ? void 0 : data.records) !== null && _data$records !== void 0 ? _data$records : []),
      registerFilter = _useClientSideDataGri.registerFilter,
      filterRegistry = _useClientSideDataGri.filterRegistry,
      deregisterFilter = _useClientSideDataGri.deregisterFilter,
      filteredData = _useClientSideDataGri.filteredData;

  if (error && !data) {
    return _react["default"].createElement(_reactUtils.BrokenPage, {
      errorMessage: error.message
    });
  }

  var columns = (0, _tableColumns.getTableColumns)(keycloakBaseURL, fhirBaseURL, extraData, t, userRole, history);
  var searchFormProps = {
    wrapperClassName: 'elongate-search-bar',
    defaultValue: (0, _reactUtils.getQueryParams)(location)[_reactUtils.searchQuery],
    onChangeHandler: function onChangeHandler(event) {
      var searchText = event.target.value;
      var nextUrl = match.url;
      var currentSParams = new URLSearchParams(location.search);

      if (searchText) {
        currentSParams.set(_reactUtils.searchQuery, searchText);
        registerFilter(nameFilterKey, function (el) {
          var _el$firstName, _el$lastName;

          var lowerSearchCase = searchText.toLowerCase();
          var firstSearch = !!((_el$firstName = el.firstName) !== null && _el$firstName !== void 0 ? _el$firstName : '').toLowerCase().includes(lowerSearchCase);
          var lastSearch = !!((_el$lastName = el.lastName) !== null && _el$lastName !== void 0 ? _el$lastName : '').toLowerCase().includes(lowerSearchCase);
          var userSearch = !!el.username.toLowerCase().includes(lowerSearchCase);
          return firstSearch || lastSearch || userSearch;
        }, searchText);
      } else {
        currentSParams["delete"](_reactUtils.searchQuery);
        deregisterFilter(nameFilterKey);
      }

      nextUrl = ''.concat(nextUrl, '?').concat(currentSParams.toString());
      history.push(nextUrl);
    }
  };
  var tableProps = {
    datasource: filteredData,
    columns: columns,
    loading: isFetching || isLoading
  };
  var title = t('User Management');
  var headerProps = {
    pageHeaderProps: {
      title: title,
      onBack: undefined
    }
  };
  return _react["default"].createElement(_reactUtils.BodyLayout, {
    headerProps: headerProps
  }, _react["default"].createElement(_reactHelmet.Helmet, null, _react["default"].createElement("title", null, title)), _react["default"].createElement(_antd.Row, {
    className: "list-view"
  }, _react["default"].createElement(_antd.Col, {
    className: "main-content"
  }, _react["default"].createElement("div", null, _react["default"].createElement("div", {
    className: "main-content__header"
  }, _react["default"].createElement(_reactUtils.SearchForm, (0, _extends2["default"])({
    "data-testid": "search-form"
  }, searchFormProps)), _react["default"].createElement(_rbac.RbacCheck, {
    permissions: ['iam_user.create', 'Practitioner.create']
  }, _react["default"].createElement(_antd.Button, {
    type: "primary",
    onClick: function onClick() {
      return history.push(_userManagement.URL_USER_CREATE);
    }
  }, _react["default"].createElement(_icons.PlusOutlined, null), t('Add User')))), _react["default"].createElement("div", {
    className: "pb-3"
  }, _react["default"].createElement(_i18n.Trans, {
    t: t,
    i18nKey: "keycloakUserStatusFilter"
  }, _react["default"].createElement(_antd.Space, null, "Status:", _react["default"].createElement(_antd.Radio.Group, {
    size: "small",
    value: (_filterRegistry$activ = filterRegistry[activeFilterKey]) === null || _filterRegistry$activ === void 0 ? void 0 : _filterRegistry$activ.value,
    buttonStyle: "solid",
    onChange: function onChange(event) {
      var val = event.target.value;

      if (val !== undefined) {
        registerFilter(activeFilterKey, function (el) {
          return el.enabled === val;
        }, val);
      } else {
        registerFilter(activeFilterKey, undefined);
      }
    }
  }, _react["default"].createElement(_antd.Radio.Button, {
    value: true
  }, "Enabled"), _react["default"].createElement(_antd.Radio.Button, {
    value: false
  }, "Disabled"), _react["default"].createElement(_antd.Radio.Button, {
    value: undefined
  }, "Show all")))))), _react["default"].createElement("div", {
    className: "dataGridWithOverview"
  }, _react["default"].createElement("div", {
    className: "dataGridWithOverview-table"
  }, _react["default"].createElement(_reactUtils.TableLayout, tableProps)), resourceId ? _react["default"].createElement("div", {
    className: "view-details-content dataGridWithOverview-overview"
  }, _react["default"].createElement(_ViewdetailsOverview.UserDetailsOverview, {
    keycloakBaseURL: keycloakBaseURL,
    resourceId: resourceId
  })) : null))));
};

exports.UserList = UserList;