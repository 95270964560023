"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateEntropy = exports.PasswordStrengthMeter = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _antd = require("antd");

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var calculateEntropy = function calculateEntropy(password) {
  var charSetSize = 0;
  if (/[a-z]/.test(password)) charSetSize += 26;
  if (/[A-Z]/.test(password)) charSetSize += 26;
  if (/[0-9]/.test(password)) charSetSize += 10;
  if (/\W|_/.test(password)) charSetSize += 32;
  return password.length > 0 ? Math.log2(Math.pow(charSetSize, password.length)) : 0;
};

exports.calculateEntropy = calculateEntropy;

var PasswordStrengthMeter = function PasswordStrengthMeter(props) {
  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      strength = _useState2[0],
      setStrength = _useState2[1];

  var getStrengthLevel = function getStrengthLevel(entropy) {
    if (entropy < 25) return 'poor';
    if (entropy < 50) return 'weak';
    if (entropy < 75) return 'good';
    return 'strong';
  };

  var handlePasswordChange = function handlePasswordChange(e) {
    var _props$onChange;

    var newPassword = e.target.value;
    setStrength(calculateEntropy(newPassword));
    (_props$onChange = props.onChange) === null || _props$onChange === void 0 ? void 0 : _props$onChange.call(props, e);
  };

  var strengthLevel = getStrengthLevel(strength);
  var strengthPercent = Math.min(strength / 100 * 100, 100);
  var inputStrokeColor = strengthLevel === 'poor' ? '#ff4d4f' : strengthLevel === 'weak' ? '#faad14' : strengthLevel === 'good' ? '#52c41a' : '#1890ff';
  return _react["default"].createElement("div", null, _react["default"].createElement(_antd.Input.Password, (0, _extends2["default"])({}, props, {
    onChange: handlePasswordChange
  })), _react["default"].createElement(_antd.Progress, {
    "data-testid": "level-".concat(strengthLevel),
    percent: strengthPercent,
    status: strengthLevel === 'poor' ? 'exception' : 'active',
    strokeColor: inputStrokeColor,
    showInfo: false
  }));
};

exports.PasswordStrengthMeter = PasswordStrengthMeter;