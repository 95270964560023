"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CodeableConcept = void 0;

var _antd = require("antd");

var _Coding = require("../Coding");

var _react = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var Text = _antd.Typography.Text;

var CodeableConcept = function CodeableConcept(props) {
  var concept = props.concept;
  var coding = concept.coding,
      text = concept.text;

  var codingsTitle = _react["default"].createElement(_react["default"].Fragment, null, (coding !== null && coding !== void 0 ? coding : []).map(function (coding, index) {
    return _react["default"].createElement(_react.Fragment, {
      key: "coding-".concat(index)
    }, _react["default"].createElement(_Coding.Coding, {
      coding: coding
    }), ",", ' ');
  }));

  return _react["default"].createElement(_antd.Tooltip, {
    "data-testid": "concept-tooltip",
    title: codingsTitle,
    color: "white"
  }, text ? _react["default"].createElement(Text, null, text) : codingsTitle);
};

exports.CodeableConcept = CodeableConcept;